import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toggleOpenBooking } from 'redux/Actions/ui-actions';

import styled from 'styled-components';

import HeaderNavigation from '../../components/Navigation/header-navigation';
import ModalStart from '../../components/Modal/modal-start';
import Booking from '../../components/Booking/booking';
import Description from '../../components/Description/description';
import Map from '../../components/Map/map';
import Footer from '../../components/Footer/footer';
import Regulations from '../../components/Regulations/regulations';

const Container = styled.div`
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const Box = styled.div`
  width: 1920px;
  min-height: 100vh;
`;

const LandingView = (props) => {
  const { openBooking, openBookingFUNC } = props;
  const [background, setBackground] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errorBackground, setErrorBackground] = useState([]);
  const [showRegulations, setShowRegulations] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showRodo, setShowRodo] = useState(false);

  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      url:
        'https://berg-regions-media-library.s3.eu-central-1.amazonaws.com/9bf8ae19-f29e-49f1-bdb0-9c4101fa7d59',
    })
      .then((response) => {
        // handle success
        setBackground(response.config.url);
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        // handle error
        setErrorBackground(error);
      });
  }, []);

  const handleShowRegulations = () => {
    setShowRegulations(!showRegulations);
  };
  const handleShowPrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };
  const handleShowRodo = () => {
    setShowRodo(!showRodo);
  };
  const handleCloseBoxRegulation = () => {
    setShowRegulations(false);
    setShowPrivacy(false);
    setShowRodo(false);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <HeaderNavigation openBooking={openBookingFUNC} />
        <Container>
          <Section>
            <Box>
              {openBooking ? (
                <Booking openBooking={openBookingFUNC} />
              ) : (
                <ModalStart openBooking={openBookingFUNC} />
              )}
            </Box>
          </Section>
        </Container>
      </div>
      {!openBooking && (
        <>
          <Container>
            <Description />
            <Map />
          </Container>
          {showRegulations && (
            <Regulations
              handleShowRegulations={handleShowRegulations}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showPrivacy && (
            <Regulations
              handleShowPrivacy={handleShowPrivacy}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showRodo && (
            <Regulations
              handleShowRodo={handleShowRodo}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          <Footer
            handleShowRegulations={handleShowRegulations}
            handleShowPrivacy={handleShowPrivacy}
            handleShowRodo={handleShowRodo}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openBookingFUNC: () => dispatch(toggleOpenBooking()),
});

const mapStateToProps = (state) => ({
  openBooking: state.UI.openBooking,
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingView);
