import * as actionTypes from '../Types/form-types';

const INITIAL_STATE = {
  phoneNumber: '',
  arrivalDate: '',
  name: '',
  registrationNumbers: '',
  street: '',
  houseNumber: '',
  city: '',
  postCode: '',
  description: '',
  code: '',
  statusVerification: '',
  checkedTerm: false,
};

const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload
          .replace(/[^\dA-Z]/g, '')
          .replace(/(.{3})/g, '$1')
          .trim(),
      };
    case actionTypes.ADD_ARRIVAL_DATE:
      return {
        ...state,
        // eslint-disable-next-line no-undef
        arrivalDate: dayjs(action.payload).format('YYYY-MM-DD'),
      };
    case actionTypes.ADD_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case actionTypes.ADD_REGISTRATION_NUMBER:
      return {
        ...state,
        registrationNumbers: action.payload,
      };
    case actionTypes.ADD_STREET:
      return {
        ...state,
        street: action.payload,
      };
    case actionTypes.ADD_HOUSE_NUMBER:
      return {
        ...state,
        houseNumber: action.payload,
      };
    case actionTypes.ADD_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case actionTypes.ADD_POST_CODE:
      return {
        ...state,
        postCode: action.payload,
      };
    case actionTypes.ADD_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case actionTypes.ADD_PIN_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case actionTypes.ADD_STATUS_VERIFICATION:
      return {
        ...state,
        statusVerification: action.payload,
      };
    case actionTypes.CHECKED__TERM:
      return {
        ...state,
        checkedTerm: !state.checkedTerm,
      };
    case actionTypes.RESET_DATA:
      return {
        ...state,
        phoneNumber: '',
        arrivalDate: '',
        name: '',
        registrationNumbers: '',
        street: '',
        houseNumber: '',
        city: '',
        postCode: '',
        description: '',
        code: '',
        statusVerification: '',
        checkedTerm: false,
      };
    default:
      return state;
  }
};

export default formReducer;
