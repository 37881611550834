import React, { useState } from 'react';

import styles from './navigation.module.scss';
import MenuLink from './menu-link';
import HamburgerIcon from '../../../assets/hamburger.png';

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav>
      {showMenu ? (
        <>
          <div className={styles.navigation__open}>
            <button
              className={styles.navigation__buttonClose}
              type="button"
              onClick={handleOpenMenu}
            >
              X
            </button>
            <MenuLink closeMenu={handleOpenMenu} handleOpenMenu={handleOpenMenu} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.navigation__openClose}>
            <MenuLink />
          </div>
          <button className={styles.navigation__button} type="button" onClick={handleOpenMenu}>
            <img src={HamburgerIcon} alt="Przycisk menu" />
          </button>
        </>
      )}

      <div className={styles.navigation__link}>
        <MenuLink />
      </div>
    </nav>
  );
};

export default Navigation;
