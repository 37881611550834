import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Poppins', sans-serif;
    background-color: #F2F2F2;
    color: black;
    margin: 0;
    padding: 0;
  }

  :root {
    //colors
    --color-black: #000000;
    --color-blue: #199cdb;
    --color-gray: #424242;
    --color-gray-light: #DDE1E3;
    --color-gray-modal: #000000a6;
    --color-white: #FFFFFF;

    //font size
    --font-size-s: 1.4rem;
    --font-size-md: 1.6rem;
    --font-size-mx: 1.8rem;
    --font-size-l: 2.4rem;
    --font-size-xl:  3rem;

     //line height
    --line-height-s: 2rem;
    --line-height-md: 2.6rem;
    --line-height-mx: 2.8rem;
    --line-height-l: 3.4rem;
    --line-height-xl:  4rem;

    //font styles
    --font-primary-regular: 400;
    --font-primary-bold: 700;

    //media queries
    --media-sm: 660px;
    --media-md: 996px;
    --media-l: 1040px;
  }

  /* Change autocomplete styles in WebKit */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
   border: 1px solid var(--color-white);
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
}
`;

export default GlobalStyle;
