import React from 'react';

import styles from './button-again.module.scss';
import AgainIcon from '../../../../assets/again.jpg';

const ButtonAgain = ({ children, onPress, clicked }) => (
  <button className={styles.button} type="button" onClick={onPress}>
    <img
      className={clicked ? styles.button__clicked : styles.button__clickedAgain}
      src={AgainIcon}
      alt="Strzałka"
    />
    {children}
  </button>
);

export default ButtonAgain;
