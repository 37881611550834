import React from 'react';

import styles from './mainForTerms.module.scss';

const TermsOfService = () => (
  <>
    <header>
      <h2 className={styles.box__header}>
        REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ ZA POŚREDNICTWEM PORTALU „L’emirGO”
      </h2>
    </header>
    <div className={styles.box}>
      <h3 className={styles.box__title}>
        Postanowienia Ogólne <p>§1</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Niniejszy dokument określa w szczególności zasady świadczenia i korzystania przez
        Użytkowników z usług elektronicznych oferowanych za pośrednictwem Portalu L’emirGO przez
        Usługodawcę, w tym proces dokonywania Rezerwacji oraz warunki Umów Usługi L’emirGO.
        Niniejszy dokument określa prawa i obowiązki Usługodawcy oraz Użytkowników i jest
        regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
        elektroniczną (Dz.U. z 2002 r. Nr 144, poz. 1204 z późn. zm.).
      </p>
      <p className={styles.box__paragraph}>
        2. Usługodawca świadczy usługi drogą elektroniczną za pośrednictwem Portalu L’emirGO w
        zakresie określonym w §4 niniejszego Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        3. Niniejszy Regulamin jest udostępniany nieodpłatnie na Stronie internetowej Usługodawcy,
        funkcjonującej w domenie (pod adresem) www.lemir24.pl, w ramach której prowadzony jest
        również Portal L’emirGO. Niniejszy Regulamin udostępniany jest w formie umożliwiającej jego
        pozyskanie, odtwarzanie, utrwalanie i wydrukowanie. Użytkownik/ Reprezentant Użytkownika ma
        możliwość zapoznania się z treścią Regulaminu w każdym czasie za pomocą odsyłacza (linku)
        zamieszczonego pod adresem w/w Strony internetowej, zakładka „Regulamin L’emirGO”.
      </p>
      <p className={styles.box__paragraph}>
        4. Rozpoczęcie korzystania z usług elektronicznych oferowanych w ramach Portalu L’emirGO
        jest równoznaczne z akceptacją warunków niniejszego Regulaminu i tym samym z zawarciem z
        Usługodawcą umowy o świadczenie usług drogą elektroniczną.
      </p>
      <p className={styles.box__paragraph}>
        5. Usługodawca nie pobiera żadnych opłat za komunikację za pośrednictwem Platformy L’emirGO
        i świadczone w jej ramach usługi elektroniczne lub w związku z wykorzystaniem środków
        porozumiewania się na odległość. Użytkownik/Reprezentant Użytkownika ponosi te koszty w
        wysokości wynikającej z odrębnej umowy zawartej pomiędzy Użytkownikiem /Reprezentantem
        Użytkownika i osobą trzecią świadczącą na rzecz Użytkownika /Reprezentanta Użytkownika
        usługi umożliwiające porozumiewanie się na odległość.
      </p>
      <p className={styles.box__paragraph}>
        6. Użytkownik zobowiązany jest do przestrzegania postanowień Regulaminu.
      </p>
      <h3 className={styles.box__title}>
        Podmiot (Usługodawca) oferujący usługi świadczone drogą elektroniczną <p>§2</p>
      </h3>
      <p className={styles.box__paragraph}>
        Podmiotem (Usługodawcą) oferującym usługi świadczone drogą elektroniczną za pośrednictwem
        Portalu „L’emirGO” jest: L&apos;emir I. Juszczyk, H. Juszczyk Spółka Jawna, ul. Sobieskiego
        16, 41-300 Dąbrowa Górnicza, NIP: 629-000-76-04. REGON 271074824, wpisana do rejestru
        przedsiębiorców prowadzonego przez Sąd Rejonowy Katowice-Wschód w Katowicach Wydział VII
        Gospodarczy KRS nr 0000047226, Email: serwis@lemir.com.pl, telefon :325088000,
      </p>
      <h3 className={styles.box__title}>
        Definicje i znaczenie zwrotów oraz terminów użytych w niniejszym Regulaminie <p>§3</p>
      </h3>
      <p className={styles.box__paragraph}>
        Definicje, zwroty i terminy użyte w niniejszym Regulaminie oznaczają:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) APS – serwis samochodowy prowadzony pod adresem ul. Sobieskiego 10A, 41-300 Dąbrowa
        Górnicza, będący częścią przedsiębiorstwa Usługodawcy;
      </p>
      <p className={styles.box__paragraphMargin}>
        2) Kalkulacja – informacja – sporządzana po dokonaniu Rezerwacji i po zdaniu kluczyka do
        Skrytki i Pojazdu Użytkownika na terenie APS, w uzgodnieniu z Użytkownikiem - o oszacowanym
        zakresie i kosztach Prac serwisowych na Pojeździe Użytkownika. Wyżej wymieniona Kalkulacja
        (informacja o zakresie i kosztach Prac serwisowych) nie stanowi oferty w rozumieniu Kodeksu
        Cywilnego, lecz zaproszenie do zawarcia Umowy Prac Serwisowych i/lub Umowy Najmu Pojazdu, o
        której mowa w art. 71 Kodeksu Cywilnego.
      </p>
      <p className={styles.box__paragraphMargin}>
        3) Kod QR – unikatowy kod przekazywany wiadomością tekstową sms, na numer telefonu
        Użytkownika /Reprezentanta Użytkownika podany przy dokonywaniu Rezerwacji, który to
        umożliwia Użytkownikowi /Reprezentantowi Użytkownika pozostawienie w Skrytce kluczyka do
        Pojazdu Użytkownika w celu ustalenia zakresu i kosztów Prac serwisowych w tym pojeździe i
        otrzymania Kalkulacji, jak również umożliwiający Użytkownikowi/ Reprezentantowi Użytkownika
        pobranie (odebranie) kluczyka do Pojazdu Użytkownika;
      </p>
      <p className={styles.box__paragraphMargin}>
        4) Konsument – osoba będąca konsumentem w rozumieniu art. 22(1) ustawy z dnia 23 kwietnia
        1964 r. Kodeks Cywilny;
      </p>
      <p className={styles.box__paragraphMargin}>
        5) Numer PIN (lub PIN) – ciąg cyfr przekazywany wiadomością tekstową sms na numer telefonu
        Użytkownika /Reprezentanta Użytkownika podany przy dokonywaniu Rezerwacji, który to
        umożliwia Użytkownikowi /Reprezentantowi Użytkownika pozostawienie w Skrytce kluczyka do
        Pojazdu Użytkownika w celu ustalenia przez Usługodawcę zakresu i kosztów Prac serwisowych w
        tym pojeździe, jak również umożliwiający Użytkownikowi/ Reprezentantowi Użytkownika pobranie
        (odebranie) kluczyka do Pojazdu Użytkownika;
      </p>
      <p className={styles.box__paragraphMargin}>
        6) Platforma L’emirGO (lub Platforma) – funkcjonujące w ramach Strony internetowej
        prowadzonej w domenie www.lemir24.pl rozwiązanie teleinformatyczne umożliwiające, za
        pośrednictwem środków porozumiewania się na odległość, zawarcie między Usługodawcą a
        Użytkownikiem, Umowy Usługi L’emirGO opisanej w niniejszym Regulaminie i na warunkach w nim
        określonych;
      </p>
      <p className={styles.box__paragraphMargin}>
        7) Pojazd Usługodawcy – samochód osobowy w rozumieniu ustawy z dnia 20 czerwca 1997 r. Prawo
        o ruchu drogowy (t.j. Dz.U.2020.110 z późn. zm.), tj. pojazd samochodowy przeznaczony
        konstrukcyjnie do przewozu nie więcej niż 9 osób łącznie z kierowcą oraz ich bagażu,
        stanowiący własność Usługodawcy lub wykorzystywany przez niego w oparciu o inny tytuł prawny
        (jak przykładowo zawarta przez Usługodawcę z właścicielem tego pojazdu umowa leasingu, umowa
        najmu), który jest przedmiotem Umowy Najmu Pojazdu zawartej na warunkach odrębnie
        uzgodnionych między Użytkownikiem (jako najemcą) a Usługodawcą (jako wynajmującym) za
        pośrednictwem środków porozumiewania się na odległość lub przy bezpośredniej obecności stron
        tej umowy;
      </p>
      <p className={styles.box__paragraphMargin}>
        8) Pojazd Użytkownika – samochód osobowy w rozumieniu ustawy z dnia 20 czerwca 1997 r. Prawo
        o ruchu drogowy (t.j. Dz.U.2020.110 z późn. zm.), (tj. pojazd samochodowy przeznaczony
        konstrukcyjnie do przewozu nie więcej niż 9 osób łącznie z kierowcą oraz ich bagażu),
        którego Użytkownik jest właścicielem lub do którego posiada tytuł prawny obejmujący do
        dysponowania nim, w tym w zakresie jego pozostawienia na terenie APS i złożenie kluczyka do
        tego samochodu do Skrytki w celu ustalenia zakresu i kosztów Prac serwisowych w tym
        samochodzie;
      </p>
      <p className={styles.box__paragraphMargin}>
        9) Prace serwisowe – usługa/i obejmująca/e w szczególności prace serwisowe, których wstępny
        zakres i koszty określa Kalkulacja, będące przedmiotem odrębnej Umowy Prac Serwisowych,
        realizowana/e na Pojeździe Użytkownika w zakresie odrębnie ustalonym między Usługodawcą a
        Użytkownikiem w tej Umowie Prac serwisowych. Prace Serwisowe nie obejmują napraw blacharsko
        lakierniczych. Usługi/prace w zakresie napraw blacharsko lakierniczych, w tym
        „powypadkowych” nie są Pracami Serwisowymi.
      </p>
      <p className={styles.box__paragraphMargin}>
        10) Przedsiębiorca – podmiot (w tym osoba fizyczna, osoba prawna, jednostka organizacyjna w
        rozumieniu art. 33(1) Kodeksu Cywilnego) posiadająca status przedsiębiorcy w rozumieniu art.
        43(1) Kodeksu Cywilnego, prowadząca działalność na podstawie wpisu do Centralnej Ewidencji i
        Informacji o Działalności Gospodarczej lub ujawniona w Krajowym Rejestrze Sądowym, lub
        ujawniona w innym właściwym państwowym rejestrze, a w wypadku podmiotu zagranicznego
        posiadająca status przedsiębiorcy na mocy prawa właściwego dla tego podmiotu i w oparciu o
        właściwy wpis do odpowiedniego rejestru prowadzonego w kraju jego siedziby;
      </p>
      <p className={styles.box__paragraphMargin}>
        11) Regulamin – niniejszy dokument, stanowiący również regulamin świadczenia usług drogą
        elektroniczną;
      </p>
      <p className={styles.box__paragraphMargin}>
        12) Reprezentant Użytkownika – osoba korzystająca z usług elektronicznych świadczonych za
        pośrednictwem Portalu L’emirGO i zawierająca z Usługodawcą w imieniu Użytkownika Umowę
        Usługi L’emirGO, będąca osobą uprawnioną do reprezentowania Użytkownika w świetle Krajowego
        Rejestru Sądowego, lub Centralnej Ewidencji i Informacji o Działalności Gospodarczej lub na
        mocy innego stosunku (tytułu) prawnego np. pełnomocnictwo;
      </p>
      <p className={styles.box__paragraphMargin}>
        13) Rezerwacja – wykonanie czynności niezbędnych w świetle Regulaminu do zawarcia Umowy
        Usługi L’emirGO opisanej w pkt 22 niniejszego paragrafu
      </p>
      <p className={styles.box__paragraphMargin}>
        14) Skrytka – urządzenie, znajdujące się na terenie APS, będące automatyczną skrytką, w
        której znajdują się schowki wyposażone w zamki elektroniczne, których otwarcie możliwe jest
        przy wykorzystaniu kodu QR lub numeru PIN, służąca przede wszystkim do składania w niej
        przez Użytkownika kluczyka do Pojazdu Użytkownika;
      </p>
      <p className={styles.box__paragraphMargin}>
        15) Umowa o świadczenie usług drogą elektroniczną – umowa zawarta między Usługodawcą
        (L’emir) a Użytkownikiem, mająca za przedmiot usługę lub usługi świadczone drogą
        elektroniczną (opisane w §4 niniejszego Regulaminu);
      </p>
      <p className={styles.box__paragraphMargin}>
        16) Usługodawca (lub „L’emir”) – podmiot wskazany w §2 niniejszego Regulaminu, a więc
        L&apos;emir I. Juszczyk, H. Juszczyk Spółka Jawna, ul. Sobieskiego 10A, 41-300 Dąbrowa
        Górnicza, NIP: 629-000-76-04. REGON 271074824, wpisana do rejestru przedsiębiorców
        prowadzonego przez Sąd Rejonowy Katowice-Wschód w Katowicach Wydział VII Gospodarczy KRS nr
        0000047226. Dane kontaktowe Usługodawcy wskazano w §2 niniejszego Regulaminu;
      </p>
      <p className={styles.box__paragraphMargin}>
        17) Użytkownik - osoba fizyczna, prawna lub jednostka organizacyjna (w tym będąca
        konsumentem albo przedsiębiorcą w rozumieniu Kodeksu Cywilnego), która osobiście lub poprzez
        Reprezentanta Użytkownika korzysta z oferowanych przez Usługodawcę usług świadczonych drogą
        elektroniczną za pośrednictwem Portalu L’emirGO (opisanych w §4 niniejszego Regulaminu), a
        także - ewentualnie – zawiera z Usługodawcą Umowę Usługi L’emirGO
      </p>
      <p className={styles.box__paragraphMargin}>
        18) Strona internetowa – strona internetowa prowadzona i administrowana przez Usługodawcę
        pod adresem (w domenie) www.lemir24.pl w ramach której prowadzona jest Platforma L’emirGO;
      </p>
      <p className={styles.box__paragraphMargin}>
        19) Umowa Najmu Pojazdu – umowa mająca za przedmiot najem od Usługodawcy Pojazdu Usługodawcy
        przez Użytkownika, zawarta za pośrednictwem środków porozumiewania się na odległość lub przy
        bezpośredniej obecności stron tej umowy; UWAGA: Umowa Najmu Pojazdu nie jest usługą
        świadczoną za pośrednictwem Portalu L’emirGO i na warunkach określonych w Regulaminie;
      </p>
      <p className={styles.box__paragraphMargin}>
        20) Umowa Prac Serwisowych – odrębna umowa między Usługodawcą a Użytkownikiem, zawarta za
        pośrednictwem środków porozumiewania się na odległość lub przy bezpośredniej obecności stron
        tej umowy, po oszacowaniu i/lub uzgodnieniu między Użytkownikiem a Usługodawcą zakresu i
        kosztów Prac serwisowych na Pojeździe Użytkownika; UWAGA: Umowa Prac Serwisowych nie jest
        usługą świadczoną za pośrednictwem Portalu L’emirGO i na warunkach określonych w
        Regulaminie;
      </p>
      <p className={styles.box__paragraphMargin}>
        21) Umowa Usługi L’emirGO – nieodpłatna Umowa obejmująca możliwość złożenia przez
        Użytkownika kluczyka do Pojazdu Użytkownika w Skrytce w celu ustalenia zakresu i kosztów
        Prac serwisowych na tym Pojeździe i otrzymania przez Użytkownika Kalkulacji;
      </p>
      <p className={styles.box__paragraphMargin}>
        22) Usługa L’emirGO – usługa obejmująca umożliwienie Użytkownikowi, po dokonaniu Rezerwacji,
        złożenia kluczyka do Pojazdu Użytkownika w Skrytce i pozostawienia Pojazdu Użytkownika w APS
        oraz otrzymania Kalkulacji, a także odbioru kluczyka do Pojazdu Użytkownika ze Skrytki, a
        tym samym odbioru Pojazdu Użytkownika z APS;
      </p>
      <p className={styles.box__paragraphMargin}>
        23) Usługa świadczona drogą elektroniczną (lub usługa elektroniczna) – zgodnie z ustawą z
        dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 r. Nr 144, poz.
        1204 z późn. zm.), usługa świadczona bez jednoczesnej obecności stron (na odległość),
        poprzez przekaz danych na indywidualne żądanie usługobiorcy (Użytkownika), przesyłana i
        otrzymywana za pomocą urządzeń do elektronicznego przetwarzania, włącznie z kompresją
        cyfrową, i przechowywania danych, która jest w całości nadawana, odbierana lub transmitowana
        za pomocą sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca 2004 r. - Prawo
        telekomunikacyjne. Usługi świadczone drogą elektroniczną na podstawie nin. Regulaminu i za
        pośrednictwem Portalu L’emirGO określa §4 Regulaminu;
      </p>
      <h3 className={styles.box__title}>
        Zakres usług świadczonych drogą elektroniczną za pośrednictwem Portalu L’emirGO i
        funkcjonalność Portalu L’emirGO<p>§4</p>
      </h3>
      <p className={styles.box__paragraph}>
        Za pośrednictwem Portalu L’emirGO Usługodawca świadczy na rzecz Użytkowników następujące
        usługi elektroniczne:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) Umożliwienie przeglądania treści zamieszczonych na Platformie L’emirGO
      </p>
      <p className={styles.box__paragraphMargin}>
        2) Umożliwianie Użytkownikom dokonywania Rezerwacji oraz zawierania Umowy Usługi L’emirGO.
      </p>
      <h3 className={styles.box__title}>
        Warunki świadczenia usług drogą elektroniczną (w tym wymagania techniczne) oraz zakaz
        dostarczania treści o charakterze bezprawnym<p>§5</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Korzystanie z Platformy L’emirGO wymaga spełnienia przez urządzenie końcowe i system
        teleinformatyczny, z którego korzysta Użytkownik/ Reprezentant Użytkownika, wymagań
        technicznych wskazanych w ust. 2 (poniżej).
      </p>
      <p className={styles.box__paragraph}>
        2. Usługodawca podaje, że minimalne wymagania techniczne, których spełnienie jest niezbędne
        do współpracy z systemem teleinformatycznym, którym posługuje się Usługodawca, w tym
        niezbędnych do dokonania Rezerwacji i zawarcia Umowy Usługi L’emirGO, obejmują:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) komputer, laptop, telefon lub inne urządzenie multimedialne z dostępem do Internetu;
      </p>
      <p className={styles.box__paragraphMargin}>2) dostęp do poczty elektronicznej;</p>
      <p className={styles.box__paragraphMargin}>
        3) przeglądarka internetowa: Mozilla Firefox w wersji 17.0 i wyższej lub Internet Explorer w
        wersji 10.0 i wyższej, Opera w wersji 12.0 i wyższej, Google Chrome w wersji 23.0. i
        wyższej, Safari w wersji 5.0 i wyższej;
      </p>
      <p className={styles.box__paragraphMargin}>
        4) zalecana minimalna rozdzielczość ekranu: 1024x768;
      </p>
      <p className={styles.box__paragraphMargin}>
        5) włączenie w przeglądarce internetowej możliwości zapisu plików Cookies oraz obsługi
        Javascript;
      </p>
      <p className={styles.box__paragraphMargin}>
        6) z wykorzystaniem telefonu; system operacyjny urządzenia mobilnego: Android w wersji 4.0.3
        lub wyższej lub iOS w wersji 9.0.4. By zawrzeć Umowę Usługi L’emirGO musi posiadać aktywny
        adres e-mail, a także klawiaturę lub inne urządzenie, umożliwiające poprawne wypełnienie
        formularzy elektronicznych.
      </p>
      <p className={styles.box__paragraph}>
        3. Zabronione jest wykorzystywanie Platformy L’emirGO w sposób lub w celach sprzecznych z
        przepisami powszechnie obowiązującego prawa i niniejszego Regulaminu. Użytkownikom zabrania
        się zwłaszcza:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) zamieszczania, przesyłania, rozpowszechniania w jakiejkolwiek formie (np. zdjęcia,
        opinie, komentarze itp.) treści erotycznych lub pornograficznych, uwidaczniających nagość
        lub sugestywnych w zakresie odnoszącym się do w/w treści;
      </p>
      <p className={styles.box__paragraphMargin}>
        2) treści naruszających polskie lub międzynarodowe przepisy prawa;
      </p>
      <p className={styles.box__paragraphMargin}>
        3) treści powszechnie uważane za moralnie naganne;
      </p>
      <p className={styles.box__paragraphMargin}>
        4) treści propagujących lub obrazujących lub demonstrujących lub zachęcających do nienawiści
        rasowej, religijnej, przemocy, dyskryminujących, przede wszystkim ze względu na pochodzenie
        rasowe lub religijne lub etniczne lub poglądy polityczne lub filozofię;
      </p>
      <p className={styles.box__paragraphMargin}>
        5) naruszające dobra osobiste lub inne dobra albo prawa osób trzecich, w tym przede
        wszystkim dobre imię, godność, wizerunek, twórczość naukową lub artystyczną;
      </p>
      <p className={styles.box__paragraphMargin}>
        6) wykorzystywania Platformy L’emirGO w celach komercyjnych, reklamowych Użytkownika lub
        osób trzecich;
      </p>
      <p className={styles.box__paragraphMargin}>
        7) zamieszczania treści zawierającej zwroty, słowa, postrzegane powszechnie za obraźliwe,
        wulgarne;
      </p>
      <p className={styles.box__paragraphMargin}>
        8) działań mogących utrudniać lub zakłócać działanie Platformy L’emirGO, w tym w
        szczególności wirusów komputerowych;
      </p>
      <p className={styles.box__paragraphMargin}>
        9) działań, w tym zamieszczania treści, mogących narazić Usługodawcę lub podmioty z nimi
        powiązane gospodarczo lub kapitałowo, albo osoby fizyczne, przede wszystkim u nich
        zatrudnione lub nimi zarządzające na utratę renomy lub dobrego imienia;
      </p>
      <p className={styles.box__paragraphMargin}>
        10) innych działań sprzecznych z celem i zasadami funkcjonowania Platformy L’emirGO.
      </p>
      <h3 className={styles.box__title}>
        Warunki oferowania i zawierania Umowy Usługi L’emirGO<p>§6</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Usługodawca za pośrednictwem Platformy L’emirGO oferuje i umożliwia Użytkownikom
        zawieranie – na podstawie postanowień niniejszego Regulaminu - Umów Usługi L’emirGO mających
        za przedmiot, i którego główną cechą świadczenia, jest umożliwienie Użytkownikowi, po
        dokonaniu Rezerwacji, złożenia kluczyka do Pojazdu Użytkownika w Skrytce i pozostawienia
        Pojazdu Użytkownika w APS, a także odbioru kluczyka do Pojazdu Użytkownika ze Skrytki, a tym
        samym odbioru Pojazdu Użytkownika z APS i otrzymania Kalkulacji o której mowa w ust. 3
        poniżej oraz §3 pkt 2 (Usługa L’emirGO).
      </p>
      <p className={styles.box__paragraph}>
        2. Usługi wskazane w ust. 1 Usługodawca świadczy NIEODPŁATNIE na warunkach niniejszego
        Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        3. Umowa Usługi L’emirGO oferowana jest w celu umożliwienia Usługodawcy uzgodnienia z
        Użytkownikiem /Reprezentantem Użytkownika zakresu i kosztów Prac serwisowych na Pojeździe
        Użytkownika oraz przedstawienia Użytkownikowi wstępnego zakresu i Kalkulacji kosztów Prac
        serwisowych. WYŻEJ WYMIENIONA WSTĘPNY ZAKRES I KALKULACJA KOSZTÓW PRAC SERWISOWYCH (DALEJ
        KALKULACJA) NIE STANOWI OFERTY W ROZUMIENIU KODEKSU CYWILNEGO, LECZ ZAPROSZENIE DO ZAWARCIA
        UMOWY PRAC SERWISOWYCH I/LUB UMOWY NAJMU POJAZDU, O KTÓREJ MOWA W ART. 71 KODEKSU CYWILNEGO.
      </p>
      <p className={styles.box__paragraph}>
        4. Usługodawca umożliwia zawarcia Umowy Usługi L’emirGO na warunkach niniejszego Regulaminu,
        poprzez dokonanie Rezerwacji. Czynności niezbędne do dokonania Rezerwacji określa §7
        niniejszego Regulaminu. DOKONUJĄC REZERWACJI UŻYTKOWNIK WYRAŻA ZGODĘ NA PRZYSTĄPIENIE DO
        WYKONANIA CZYNNOŚCI OKREŚLONYCH W UST. 3 PRZED UPŁYWEM TERMINU DO SKORZYSTANIA Z USTAWOWEGO
        PRAWA DO ODSTĄPIENIA OD UMOWY.
      </p>
      <p className={styles.box__paragraph}>
        5. Po dokonaniu Rezerwacji, o której mowa w §7 (poniżej) i złożeniu kluczyka do Pojazdu
        Użytkownika oraz samego Pojazdu Użytkownika na terenie APS, najpóźniej w ciągu dwóch dni
        roboczych przedstawiciel (pracownik) APS skontaktuje się z Użytkownikiem/ Reprezentantem
        Użytkownika, na numer telefonu lub adres email wskazany w trakcie Rezerwacji, celem
        ustalenia dokładnego zakresu oczekiwanych Prac serwisowych i przekazania Kalkulacji.
      </p>
      <p className={styles.box__paragraph}>
        6. ZAWIERAJĄC, POPRZEZ REZERWACJĘ, UMOWĘ USŁUGI L’EMIRGO UŻYTKOWNIK / REPREZENTANT
        UŻYTKOWNIKA WYRAŻA ZGODĘ NA POBRANIE PRZEZ USŁUGODAWCĘ KLUCZYKA DO POJAZDU UŻYTKOWNIKA I
        WYKONANIA NA POJEŹDZIE UŻYTKOWNIKA W APS CZYNNOŚCI NIEZBĘDNYCH DO USTALENIA ZAKRESU PRAC
        SERWISOWYCH, W TYM RÓWNIEŻ ODBYCIA TZW. JAZDY TESTOWEJ PO DROGACH PUBLICZNYCH.
      </p>
      <p className={styles.box__paragraph}>
        7. Kalkulacja, o której mowa w ust. 3, zostanie przekazana po uzgodnieniu dokładnego zakresu
        tych prac między Usługodawcą i Użytkownikiem i przeprowadzeniu czynności określonych w ust.
        6, w terminie dwóch dni roboczych od dnia pozostawienia Pojazdu Użytkownika na terenie APS
        oraz kluczyka do tego Pojazdu w Skrytce.
      </p>
      <p className={styles.box__paragraph}>
        8. W przypadku niemożliwości wykonania w/w czynności we wskazanym w ust. 7 terminie
        Usługodawca zawiadomi o tym fakcie Użytkownika/ Reprezentanta Użytkownika wskazując powód
        niewykonania tych czynności i nowy termin ich wykonania. Powiadomienie nastąpi za
        pośrednictwem email lub telefonicznie na adres email/numer telefonu wskazany w trakcie
        Rezerwacji.
      </p>
      <p className={styles.box__paragraph}>
        9. Warunki zawarcia Umowy Prac Serwisowych i/lub Umowy Najmu Pojazdu, jak również zakres
        praw i obowiązków stron tych Umów są uregulowane w odrębnych dokumentach. Umowa Prac
        Serwisowych i/lub Umowa Najmu Pojazdu, ich warunki, jak również sposób ich zawierania między
        Użytkownikiem a Usługodawcą, nie są regulowane niniejszym Regulaminem.
      </p>
      <p className={styles.box__paragraph}>
        10. Po przekazaniu Kalkulacji, o której mowa w ust. 3 (powyżej) Usługodawca przekaże
        Użytkownikowi na adres email lub telefonicznie (na adres/numer wskazany w trakcie
        Rezerwacji), informację o możliwości odbioru Pojazdu Użytkownika i kluczyka do tego Pojazdu.
        Odbiór Pojazdu Użytkownika i kluczyka do Pojazdu nastąpi za pośrednictwem Skrytki – lub na
        żądanie Użytkownika (złożone email lub telefonicznie) – przy bezpośredniej obecności stron.
        Usługodawca prześle również na numer telefonu Użytkownika/Reprezentanta Użytkownika (podany
        w przy dokonywaniu Rezerwacji) wiadomość tekstową sms zawierająca kod QR i numer PIN,
        umożliwiająca odebranie kluczyka do Pojazdu Użytkownika ze Skrytki
      </p>
      <p className={styles.box__paragraph}>
        11. Usługodawca podaje, że ze względów bezpieczeństwa, kod QR i numer PIN, przekazane
        zgodnie z ust. 10, wygasają po upływie określonego czasu od dnia ich przesłania wiadomością
        sms. O wygaśnięciu, przed 11. odbiorem Pojazdu Użytkownika przez Użytkownika, kodu QR i
        numeru PIN, Usługodawca zawiadomi Użytkownika w sposób jak opisany w ust. 10.
      </p>
      <p className={styles.box__paragraph}>
        12. Użytkownik jest zobowiązany do odbioru Pojazdu Użytkownika i kluczyka do tego Pojazdu
        najpóźniej w terminie 7dni od dnia otrzymania informacji (poprzez sms) o możliwości odbioru
        Pojazdu Użytkownika.
      </p>
      <p className={styles.box__paragraph}>
        13. UWAGA: Dokonując Rezerwacji Użytkownik /Reprezentant Użytkownika wyraża zgodę na kontakt
        z tym Użytkownikiem / Reprezentantem Użytkownika w celu umożliwienia Usługodawcy uzgodnienia
        z Użytkownikiem /Reprezentantem Użytkownika zakresu i kosztów Prac serwisowych na Pojeździe
        Użytkownika oraz przedstawienia Użytkownikowi wstępnego zakresu i kalkulacji kosztów Prac
        serwisowych (Kalkulacji).
      </p>
      <h3 className={styles.box__title}>
        Rezerwacja<p>§7</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. W celu dokonania Rezerwacji Użytkownik musi wypełnić formularz interaktywny
        (elektroniczny) zamieszczony w zakładce L’emirGO (Platformie L’emirGO) prowadzonej na
        Stronie internetowej.
      </p>
      <p className={styles.box__paragraph}>
        2. Minimalny zakres danych i informacji niezbędnych do dokonania Rezerwacji obejmuje
        podanie:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) Imienia i nazwiska osoby dokonującej Rezerwacji (Użytkownika/Reprezentanta Użytkownika),
        a w przypadku, gdy Rezerwacji dokonuje Reprezentant Użytkownika, również dane samego
        Użytkownika (imię i nazwisko lub nazwę/firmę Użytkownika, NIP Użytkownika – o ile jest on
        Przedsiębiorcą)
      </p>
      <p className={styles.box__paragraphMargin}>
        2) numeru telefonu osoby dokonującej Rezerwacji tj. Użytkownika/Reprezentanta Użytkownika;
      </p>
      <p className={styles.box__paragraphMargin}>
        3) adresu poczty elektronicznej osoby dokonującej Rezerwacji tj. Użytkownika /Reprezentanta
        Użytkownika;
      </p>
      <p className={styles.box__paragraphMargin}>4) numeru rejestracyjnego Pojazdu Użytkownika</p>
      <p className={styles.box__paragraphMargin}>
        5) dokonania wyboru przez Użytkownika /Reprezentanta Użytkownika daty dziennej (dalej data
        zdania), w której nastąpić ma pozostawienie Pojazdu Użytkownika i złożenie kluczyka do
        Skrytki. UWAGA: data zdania nie może być przypadać wcześniej niż 1 godzina i jednocześnie
        później niż jeden miesiąc od momentu zatwierdzenia danych zgodnie z ust. 12 (poniżej).
      </p>
      <p className={styles.box__paragraph}>
        3. Niezależnie od ust. 2 (powyżej) Użytkownik /Reprezentant Użytkownika w trakcie Rezerwacji
        i w ramach formularza interaktywnego (elektronicznego) składa poniższe oświadczenia:
      </p>
      <p className={styles.box__paragraphMargin}>1) o akceptacji niniejszego Regulaminu;</p>
      <p className={styles.box__paragraphMargin}>
        2) o zapoznaniu się z Klauzulą Informacyjną Ochrony Danych Osobowych oraz Polityką
        Prywatności;
      </p>
      <p className={styles.box__paragraphMargin}>
        3) że Użytkownik dysponuje tytułem prawnym do Pojazdu Użytkownika, który uprawnia go do
        zadysponowania tym Pojazdem w zakresie Umowy Usługi L’emirGO;
      </p>
      <p className={styles.box__paragraphMargin}>
        4) że podane przez niego przy składaniu rezerwacji dane osobowe, w tym numer telefonu, są
        prawdziwe
      </p>
      <p className={styles.box__paragraphMargin}>
        5) że wyraża zgodę na niezwłoczne pobranie kluczyka do Pojazdu Użytkownika ze Skrytki i
        przystąpienia do wykonania przez Usługodawcę czynności niezbędnych do oszacowania
        niezbędnych Prac serwisowych w Pojeździe Użytkownika oraz ich kosztów i przekazania
        Kalkulacji – względnie w wypadku wskazania przez Użytkownika zakresu prac – oszacowania ich
        wartości.
      </p>
      <p className={styles.box__paragraph}>
        4. Poza informacjami i danymi określonymi w ust. 3 (powyżej) osoba dokonująca Rezerwacji
        (Użytkownik /Reprezentant Użytkownika) może podać dodatkowo zakres oczekiwanych przez niego
        Prac serwisowych na Pojeździe Użytkownika lub opis niesprawności/ żądany zakres prac dla
        tego Pojazdu.
      </p>
      <p className={styles.box__paragraph}>
        5. Usługodawca wskazuje, iż podanie danych określonych w ust. 3 jest dobrowolne, lecz ich
        niewskazanie może uniemożliwić skorzystanie z Usługi L’emirGO. Informacje dotyczące ochrony
        danych osobowych i warunków ich przetwarzania określa §15 i załącznik do Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        6. Po wprowadzeniu w formularz interaktywny (elektroniczny) danych i informacji określonych
        w ust. 3 – i ewentualnie ust. 4, Użytkownik/Reprezentant Użytkownika dokonuje zatwierdzenia
        danych poprzez naciśnięcie przycisku „idź dalej”.
      </p>
      <p className={styles.box__paragraph}>
        7. Po dokonaniu w ramach Rezerwacji czynności określonych w ust. 1-6 na wskazany przez
        Użytkownika /Reprezentanta Użytkownika adres email, przesłana zostanie przez Usługodawcę
        wiadomość zawierająca dane i informacje oraz oświadczenia złożone w toku Rezerwacji, w tym
        datę zdania Pojazdu Użytkownika, wraz z załączonym do niego Regulaminem, Pouczeniem o prawie
        odstąpienia oraz Klauzulą Informacyjną. W wiadomości email, o której mowa powyżej,
        zamieszczony będzie przycisk „Potwierdzam Rezerwację”.
      </p>
      <p className={styles.box__paragraph}>
        8. Użytkownik celem zawarcia Umowy Usługi L’emirGO musi dokonać potwierdzenia Rezerwacji,
        poprzez przyciśnięcie przycisku „Potwierdzam Rezerwację” zamieszczonego w wiadomości email
        przekazanej zgodnie z ust. 11 (powyżej).
      </p>
      <p className={styles.box__paragraph}>
        9. Umowa Usługi L’emirGO zostaje zawarta z momentem potwierdzenia Rezerwacji zgodnie z ust.
        7 i 8 (powyżej).
      </p>
      <p className={styles.box__paragraph}>
        10. Po dokonaniu potwierdzenia Rezerwacji, najpóźniej na 24h przed datą zdania Pojazdu
        Użytkownika na numer telefonu Użytkownika/Reprezentanta Użytkownika (podany w przy
        dokonywaniu Rezerwacji) przesłana zostanie wiadomość tekstowa sms zawierająca kod QR i numer
        PIN, umożliwiająca złożenie kluczyka do Pojazdu Użytkownika w Skrytce.
      </p>
      <p className={styles.box__paragraph}>
        11. Usługodawca podaje, że ze względów bezpieczeństwa, kod QR i numer PIN wygasają po
        upływie określonego czasu od dnia ich przesłania w sposób opisany w ust. 10. O wygaśnięciu,
        przed datą zdania Pojazdu Użytkownika, kodu QR i numeru PIN Usługodawca zawiadomi
        Użytkownika w sposób jak opisany w ust. 10.
      </p>
      <p className={styles.box__paragraph}>
        12. UWAGA: Dokonując Rezerwacji Użytkownik /Reprezentant Użytkownika wyraża zgodę na kontakt
        z tym Użytkownikiem / Reprezentantem Użytkownika w celu umożliwienia Usługodawcy uzgodnienia
        z Użytkownikiem /Reprezentantem Użytkownika zakresu i kosztów Prac serwisowych na Pojeździe
        Użytkownika oraz przedstawienia Użytkownikowi wstępnego zakresu i kalkulacji kosztów Prac
        serwisowych (Kalkulacji).
      </p>
      <h3 className={styles.box__title}>
        Bezpieczeństwo<p>§8</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Usługodawca zapewnia środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia
        bezpieczeństwa świadczonych funkcjonalności lub usług na podstawie umowy usługi
        elektronicznej. Korzystanie z usług elektronicznych wiąże się z typowymi zagrożeniami
        dotyczącymi przekazywania danych poprzez Internet oraz wiadomościami tekstowymi sms, takimi
        jak ich rozpowszechnienie, utrata lub uzyskiwanie do nich dostępu przez osoby nieuprawnione.
      </p>
      <p className={styles.box__paragraph}>
        2. Użytkownik zobowiązany jest zabezpieczyć kod QR i numer PIN otrzymany za pośrednictwem
        wiadomości tekstowej sms przed ujawnieniem osobom trzecim.
      </p>
      <p className={styles.box__paragraph}>
        3. Użytkownik ponosi całkowitą i wyłączną odpowiedzialność za wszelkie skutki wynikłe z
        korzystania z Portalu L’emirGO przez osoby nieuprawnione, które w jakikolwiek sposób weszły
        w posiadanie kodu QR i numeru PIN Użytkownika bez winy Usługodawcy.
      </p>
      <p className={styles.box__paragraph}>
        4. W przypadku podejrzenia ujawnienia danych dostępowych (kodu QR i numeru PIN) Użytkownicy
        powinni niezwłocznie zawiadomić o tym fakcie Usługodawcę.
      </p>
      <h3 className={styles.box__title}>
        Reklamacje usług elektronicznych (nie dotyczy reklamacji przedmiotu Umowy Usługi L’emirGO)
        <p>§9</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Usługodawca podejmuje działania w celu zapewnienia w pełni poprawnego działania Portalu
        L’emirGO w takim zakresie, jaki wynika z aktualnej wiedzy technicznej i zobowiązuje się
        usunąć w rozsądnym terminie wszelkie nieprawidłowości zgłoszone przez Użytkownika.
      </p>
      <p className={styles.box__paragraph}>
        2. Użytkownik powinien niezwłocznie powiadomić Usługodawcę dostrzeżonych nieprawidłowościach
        lub przerwach w funkcjonowaniu Platformy L’emirGO.
      </p>
      <p className={styles.box__paragraph}>
        3. Reklamacje związane ze świadczeniem usług elektronicznych przez Użytkownika oraz
        pozostałe reklamacje związanie z działaniem Platformy L’emirGO (z wyłączeniem procedury
        reklamacji dotyczącej usługi będącej przedmiotem Umowy Usługi L’emirGO, tj. umowy zawartej
        za pośrednictwem Portalu L’emirGO), Użytkownik może składać na adres L’emir 41-300 Dąbrowa
        Górnicza ul. Sobieskiego 10a; lub w formie elektronicznej za pośrednictwem poczty
        elektronicznej na adres: serwis@lemir.com.pl;
      </p>
      <p className={styles.box__paragraph}>
        4. Zaleca się podanie przez Użytkownika w opisie reklamacji:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i
        daty wystąpienia nieprawidłowości;
      </p>
      <p className={styles.box__paragraphMargin}>2) żądania Użytkownika; oraz</p>
      <p className={styles.box__paragraphMargin}>
        3)danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie
        reklamacji przez Usługodawcę.
      </p>
      <p className={styles.box__paragraph}>
        5. Ustosunkowanie się do reklamacji przez Usługodawcę następuje nie później niż w terminie
        14 dni kalendarzowych od dnia jej skutecznego złożenia. Usługodawca zobowiązuje się do
        rozpatrzenia każdej reklamacji w terminie do 14 dni, a gdyby to nie było możliwe, do
        poinformowania w tym okresie Użytkownika, kiedy reklamacja zostanie rozpatrzona.
      </p>
      <h3 className={styles.box__title}>
        Reklamacje dotyczące Umowy Usługi L’emiGO
        <p>§10</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Podstawa i zakres odpowiedzialności Usługodawcy z tytułu niewykonania lub nienależytego
        wykonania Umowy Usługi L’emirGO regulują przepisy ustawy Kodeks Cywilny. Powyższe nie
        dotyczy Użytkowników nie będących Konsumentami, dla których zakres odpowiedzialności
        Usługodawcy określono w §16 niniejszego Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        2. Usługodawca zobowiązany jest do wykonania Umowy Usługi L’emirGO z należytą starannością.
      </p>
      <p className={styles.box__paragraph}>
        3. Reklamacja może zostać złożona przez Użytkownika przykładowo:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) pisemnie na adres: L’emir 41-300 Dąbrowa Górnicza ul. Sobieskiego 10a
      </p>
      <p className={styles.box__paragraphMargin}>2) Email na adres: serwis@lemir.com.pl</p>
      <p className={styles.box__paragraph}>
        4. Ustosunkowanie się do reklamacji przez Usługodawcę następuje nie później niż w terminie
        14 dni kalendarzowych od dnia jej skutecznego złożenia. Usługodawca zobowiązuje się do
        rozpatrzenia reklamacji w terminie do 14 dni, a gdyby to nie było możliwe, do poinformowania
        w tym okresie Użytkownika, kiedy reklamacja zostanie rozpatrzona.
      </p>
      <h3 className={styles.box__title}>
        Rozwiązanie umowy o świadczenie usług elektronicznych (nie dotyczy rozwiązania Usługi
        L’emirGO) i wstrzymanie dostępu do Portalu L’emirGO, zmiany Regulaminu
        <p>§11</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Usługodawca zastrzega sobie możliwość rozwiązania umowy o świadczenie usług drogą
        elektroniczną, w tym zaprzestania świadczenia tych usług w każdym czasie.
      </p>
      <p className={styles.box__paragraph}>
        2. Użytkownik może zaprzestać korzystania z Portalu L’emirGO do momentu zatwierdzenia danych
        podanych w formularzu interaktywnym (elektronicznym) w trakcie Rezerwacji, zgodnie §7 ust.
        6, w tym poprzez opuszczenie (wyjście z) Portalu L’emirGO.
      </p>
      <p className={styles.box__paragraph}>
        3. Również po zatwierdzeniu danych, zgodnie z §7 ust.6 Użytkownik może nie dokonywać
        Potwierdzenia Rezerwacji zgodnie z §7 ust. 8.
      </p>
      <p className={styles.box__paragraph}>
        4. W sytuacjach opisanych w ust. 1 i 2 nie dochodzi do zawarcia Umowy Usługi L’emirGO, jak
        również następuje rozwiązanie umowy o świadczenie usług elektronicznych.
      </p>
      <p className={styles.box__paragraph}>
        5. Rozwiązanie umowy o świadczenie usług elektronicznych nie rodzi żadnych kosztów po
        stronie Użytkownika.
      </p>
      <p className={styles.box__paragraph}>
        6. Usługodawca może pozbawić Użytkownika, który dokonał Rezerwacji, praw do korzystania z
        Portalu L’emirGO, jak również może ograniczyć jego dostęp do części lub całości zasobów
        Portalu L’emirGO - ze skutkiem natychmiastowym - poprzez przesłanie na adres email podany w
        trakcie Rezerwacji, w przypadku naruszenia postanowień Regulaminu:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) Użytkownik/ Reprezentant Użytkownika podał w trakcie Rezerwacji dane i informacje
        określone w §6 ust. 7 pkt 1-3) niezgodne z prawdą, wprowadzające w błąd Usługodawcę lub
        naruszające prawa osób trzecich.
      </p>
      <p className={styles.box__paragraphMargin}>
        2) Użytkownik naruszył obowiązki określone w §5 ust. 3 Regulaminu
      </p>
      <p className={styles.box__paragraph}>
        7. Usługodawca jest uprawniony do przerw lub zakłóceń w świadczeniu usług drogą
        elektroniczną i udostępnianiu Portalu L’emirGO jeśli powodem jest:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) modyfikacja, modernizacja, rozbudowa lub konserwacja systemu teleinformatycznego lub
        oprogramowania Usługodawcy
      </p>
      <p className={styles.box__paragraphMargin}>
        2) siła wyższa, działania lub zaniechania osób trzecich (działania niezależne od
        Usługodawcy)
      </p>
      <p className={styles.box__paragraph}>
        8. Usługodawca zastrzega sobie prawo do zmiany lub zaprzestania udostępniania Platformy
        L’emirGO, rezygnacji ze świadczenia usług elektronicznych lub wprowadzania zmian do tych
        usług, a także wprowadzania nowych serwisów internetowych lub usług elektronicznych, w
        każdym czasie.
      </p>
      <p className={styles.box__paragraph}>
        9. Usługodawca może informować użytkowników Portalu L’emirGO o zmianach lub zaprzestaniu
        udostępniania serwisów internetowych lub usług elektronicznych w szczególności poprzez
        zamieszczenie stosownego oświadczenia Usługodawcy na stronach internetowych Portalu L’emirGO
        lub Stronie internetowej na której prowadzony jest Portal L’emirGO.
      </p>
      <p className={styles.box__paragraph}>
        10. Usługodawca zastrzega sobie również prawo do zmiany Regulaminu w każdym czasie. O
        zmianie Regulaminu Usługodawca zawiadomi Użytkowników w sposób opisany w ust. 9 z 7 dniowym
        wyprzedzeniem. Zmiany Regulaminu wchodzą w życie po upływie o w/w terminu. Zmiana Regulaminu
        nie ma wpływu na treść Umowy Usługi L’emirGO zawartych wg warunków Regulaminu obowiązującego
        w chwili ich zawierania.
      </p>
      <h3 className={styles.box__title}>
        Rozwiązanie Umowy Usługi L’emirGO
        <p>§12</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Użytkownik, który zawarł Umowę Usługi L’emirGO, może rozwiązać tą umowę do czasu jej
        wykonania przez Usługodawcę (tj. do czasu przekazania Użytkownikowi Kalkulacji o której mowa
        w §6 ust. 3 Regulaminu, bez ponoszenia z tego tytułu jakichkolwiek kosztów.
      </p>
      <p className={styles.box__paragraph}>
        2. Rozwiązanie Umowy Usługi L’emirGO następuje również poprzez niezłożenie w dacie zdania
        (§7 ust. 2 pkt 5 Regulaminu), kluczyków do Pojazdu Użytkownika i niepozostawienia Pojazdu
        Użytkownika w APS.
      </p>
      <p className={styles.box__paragraph}>
        3. Po zdaniu kluczyka do Pojazdu Użytkownika Użytkownik może złożyć oświadczenie o
        rozwiązaniu Umowy Usługi L’emirGO na adres email Usługodawcy serwis@lemir.com.pl.
        Rozwiązanie Umowy Usługi L’emirGO po złożeniu kluczyka do Pojazdu Użytkownika do Skrytki
        następuje z 7 dniowym wypowiedzeniem.
      </p>
      <p className={styles.box__paragraph}>
        4. W sytuacji, o której mowa powyżej, Usługodawca – w terminie 7 od dnia otrzymania
        oświadczenia o rozwiązaniu umowy - prześle Użytkownikowi informację o dacie odbioru przez
        użytkownika Pojazdu Użytkownika, jak również kod QR i numer PIN.
      </p>
      <p className={styles.box__paragraph}>
        5. Kod QR i numer PIN zostanie wysłany Użytkownikowi przez Usługodawcę na numer telefonu
        Użytkownika/Reprezentanta Użytkownika (podany w przy dokonywaniu Rezerwacji).
      </p>
      <p className={styles.box__paragraph}>
        6. Usługodawca podaje, że ze względów bezpieczeństwa, kod QR i numer PIN wygasają po upływie
        określonego czasu od dnia ich przesłania w sposób opisany w ust. 4. O wygaśnięciu, kodu QR i
        numeru PIN Usługodawca zawiadomi Użytkownika w sposób jak opisany w ust. 4, a także – w ten
        sam sposób – przekaże Użytkownikowi nowy kod QR i numer PIN.
      </p>
      <p className={styles.box__paragraph}>
        7. Odbiór Pojazdu Użytkownika możliwy jest również, w wypadku złożenia takiego żądania przez
        Użytkownika, przy bezpośredniej obecności stron.
      </p>
      <p className={styles.box__paragraph}>
        8. Mając na uwadze cel na jaki zdany został Pojazd Użytkownika i kluczyk do tego pojazdu,
        Usługodawca zapewni możliwość odbioru kluczyka do Pojazdu Użytkownika oraz samego Pojazdu
        Użytkownika nie później niż do upływu okresu wypowiedzenia określonego w ust. 3 (powyżej).
      </p>
      <h3 className={styles.box__title}>
        Ustawowe prawo odstąpienia od umowy przez konsumenta
        <p>§13</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Konsument, który zawarł umowę na odległość, może odstąpić od niej bez podawania przyczyny
        i bez ponoszenia jakichkolwiek kosztów innych niż przewidziane przez przepisy prawa, w
        terminie 14 dni od dnia zawarcia umowy (dalej również „Ustawowe prawo odstąpienia”).
      </p>
      <p className={styles.box__paragraph}>
        2. Do zachowania terminu wystarczy wysłanie przez Użytkownika będącego Konsumentem
        oświadczenia przed upływem terminu do odstąpienia od umowy wskazanego w ust. 1.
      </p>
      <p className={styles.box__paragraph}>
        3. Konsument może złożyć jakiekolwiek jednoznaczne oświadczenie, w którym poinformuje o
        swoim odstąpieniu od Umowy Usługi L’emirGO. Oświadczenie o odstąpieniu od Umowy L’emirGO
        może zostać złożone np.:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) pisemnie na adres: L&apos;emir I. Juszczyk, H. Juszczyk Spółka Jawna, 41-300 Dąbrowa
        Górnicza ul. Sobieskiego 10a
      </p>
      <p className={styles.box__paragraphMargin}>
        2) w formie elektronicznej za pośrednictwem poczty elektronicznej na adres:
        serwis@lemir.com.pl
      </p>
      <p className={styles.box__paragraphMargin}>
        3) z wykorzystaniem formularza odstąpienia, który stanowi załącznik do niniejszego
        Regulaminu
      </p>
      <p className={styles.box__paragraph}>
        4. Konsument może skorzystać z wzoru formularza odstąpienia, o którym mowa w ust. 3 pkt 3
        powyżej jednak nie jest to obowiązkowe.
      </p>
      <p className={styles.box__paragraph}>
        5. W przypadku odstąpienia od umowy zawartej na odległość umowę uważa się za niezawartą.
      </p>
      <p className={styles.box__paragraph}>
        6. Prawo odstąpienia od umowy zawartej na odległość, o którym mowa w ust. 1 (powyżej), nie
        przysługuje Konsumentowi w przypadkach wskazanych w art. 38 ustawy z dnia 30 maja 2014 r. o
        prawach konsumenta, w szczególności w odniesieniu do umów:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą
        konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu
        świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy;
      </p>
      <p className={styles.box__paragraphMargin}>
        2) w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi
        przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do
        odstąpienia od umowy;
      </p>
      <p className={styles.box__paragraphMargin}>
        3) w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według
        specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;
      </p>
      <p className={styles.box__paragraphMargin}>
        4) w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca
        krótki termin przydatności do użycia;
      </p>
      <p className={styles.box__paragraphMargin}>
        5) w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu,
        której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze
        względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;
      </p>
      <p className={styles.box__paragraphMargin}>
        6) w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój
        charakter, zostają nierozłącznie połączone z innymi rzeczami;
      </p>
      <p className={styles.box__paragraphMargin}>
        7) w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona
        przy zawarciu umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30
        dni i których wartość zależy od wahań na rynku, nad którymi przedsiębiorca nie ma kontroli;
      </p>
      <p className={styles.box__paragraphMargin}>
        8) w której konsument wyraźnie żądał, aby przedsiębiorca do niego przyjechał w celu
        dokonania pilnej naprawy lub konserwacji; jeżeli przedsiębiorca świadczy dodatkowo inne
        usługi niż te, których wykonania konsument żądał, lub dostarcza rzeczy inne niż części
        zamienne niezbędne do wykonania naprawy lub konserwacji, prawo odstąpienia od umowy
        przysługuje konsumentowi w odniesieniu do dodatkowych usług lub rzeczy;
      </p>
      <p className={styles.box__paragraphMargin}>
        9) w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy
        komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po
        dostarczeniu;
      </p>
      <p className={styles.box__paragraphMargin}>
        10) o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę;
      </p>
      <p className={styles.box__paragraphMargin}>11) zawartej w drodze aukcji publicznej;</p>
      <p className={styles.box__paragraphMargin}>
        12) o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych,
        przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem,
        wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie oznaczono dzień lub
        okres świadczenia usługi;
      </p>
      <p className={styles.box__paragraphMargin}>
        13) o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli
        spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do
        odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia
        od umowy.
      </p>
      <p className={styles.box__paragraph}>
        7. Zgodnie z art. 37 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, w chwili
        odstąpienia przez Konsumenta od umowy zawartej na odległość lub poza lokalem
        przedsiębiorstwa wygasają powiązane z nią umowy dodatkowe zawarte przez Konsumenta, jeżeli
        na ich podstawie świadczenie jest spełniane przez przedsiębiorcę lub osobę trzecią na
        podstawie porozumienia z przedsiębiorcą. Konsument nie ponosi kosztów związanych z
        wygaśnięciem tych umów, z wyjątkiem przewidzianych w przepisach wyżej wymienionej ustawy.
      </p>
      <p className={styles.box__paragraph}>
        8. Usługodawca nie pobiera żadnych płatności w związku z Usługą L’emirGO.
      </p>
      <p className={styles.box__paragraph}>
        9. Usługodawca informuje, że zawierając Umowę Usługi L’emirGO Użytkownik wyraża zgodę na
        przystąpienie do wykonania czynności obejmujących ocenę stanu Pojazdu Użytkownika w celu
        przygotowania Kalkulacji przed upływem terminu do skorzystania z ustawowego prawa do
        odstąpienia od umowy (§7 ust. 3 pkt 5 Regulaminu).
      </p>
      <h3 className={styles.box__title}>
        Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
        <p>§14</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Szczegółowe informacje dotyczące możliwości skorzystania przez Użytkownika będącego
        Konsumentem z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz
        zasady dostępu do tych procedur dostępne są na stronie internetowej Urzędu Ochrony
        Konkurencji i Konsumentów pod adresem: http://polubowne.uokik.gov.pl/
      </p>
      <p className={styles.box__paragraph}>
        2. Użytkownikowi będącemu Konsumentem przysługują następujące przykładowe możliwości
        pozasądowego rozpatrywania sporów i dochodzenia roszczeń:
      </p>
      <p className={styles.box__paragraphMargin}>
        1) wniosek o rozstrzygnięcie sporu do stałego polubownego sądu konsumenckiego
      </p>
      <p className={styles.box__paragraphMargin}>
        2) wniosek w sprawie pozasądowego rozwiązania sporu do wojewódzkiego inspektora Inspekcji
        Handlowej (więcej informacji na stronie inspektora właściwego ze względu na miejsce
        wykonywania działalności gospodarczej przez Sprzedawcę); oraz
      </p>
      <p className={styles.box__paragraphMargin}>
        3) wystąpienie o pomoc do powiatowego (miejskiego) rzecznika konsumentów lub organizacji
        społecznej, do której zadań statutowych należy ochrona konsumentów Usługodawca podaje, że
        dostępna jest również platforma internetowego systemu rozstrzygania sporów pomiędzy
        konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR). Wyżej wymieniona
        platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej
        obsługi dla konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu
        dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o
        świadczenie usług. Platforma zamieszczona jest pod adresem pod adresem
        http://ec.europa.eu/consumers/odr
      </p>
      <p className={styles.box__paragraph}>
        3. Usługodawca informuje, że przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa
        punkt kontaktowy. Dane punktu kontaktowego można odnaleźć na stronie UOKIK
        http://polubowne.uokik.gov.pl/kontakt,7,pl.html (telefon: 22 55 60 333, email:
        kontakt.adr@uokik.gov.pl lub adres pisemny: Pl. Powstańców Warszawy 1, 00-950 Warszawa).
        Powyższy punkt kontaktowy służy w szczególności udzielaniem między innymi udzielanie pomocy
        konsumentom w sprawach dotyczących pozasądowego rozwiązywania sporów konsumenckich.
      </p>
      <h3 className={styles.box__title}>
        Ochrona danych osobowych
        <p>§15</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Administratorem danych osobowych Użytkowników jest L&apos;emir I.Juszczyk, H.Juszczyk
        Spółka Jawna, ul. Sobieskiego 16, 41-300 Dąbrowa Górnicza, NIP: 629-000-76-04. REGON
        271074824, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy
        Katowice-Wschód w Katowicach Wydział VII Gospodarczy KRS nr 0000047226.
      </p>
      <p className={styles.box__paragraph}>
        2. Administrator dokłada starań, aby odpowiednio zabezpieczyć dane osobowe i w sposób
        zrozumiały przedstawić sposób korzystania przez niego z tych danych.
      </p>
      <p className={styles.box__paragraph}>
        3. Administrator przetwarza dane osobowe zgodnie z przepisami prawa powszechnie
        obowiązującego, w tym w szczególności Rozporządzenia Parlamentu Europejskiego i Rady (UE)
        2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
        uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (RODO) oraz ustawy z
        dnia 10 maja 2018 r. o ochronie danych osobowych.
      </p>
      <p className={styles.box__paragraph}>
        4. Szczegółowe informacje służące wskazaniu Użytkownikom i/lub Reprezentantom Użytkowników
        zasad przetwarzania danych osobowych podanych przez tych Użytkowników i/lub Reprezentantów
        Użytkowników w trakcie Rezerwacji, określa Klauzula Informacyjna stanowiąca załącznik do
        niniejszego Regulaminu.
      </p>
      <h3 className={styles.box__title}>
        Postanowienia dotyczące klientów nie będących konsumentami
        <p>§16</p>
      </h3>
      <p className={styles.box__paragraph}>
        1. Niniejszy paragraf Regulaminu dotyczy wyłącznie Użytkowników niebędących Konsumentami.
      </p>
      <p className={styles.box__paragraph}>
        2. Usługodawca może rozwiązać umowę o świadczenie usług lub Umowę Usługi L’emirGO z
        Użytkownikiem nie będącym Konsumentem w każdym czasie i żądać odbioru kluczyka do Pojazdu
        Użytkownika oraz odebrania Pojazdu Użytkownika z APS. W przypadku nieodebrania Pojazdu
        Użytkownika mimo rozwiązania Umowy Usługi L’emirGO w terminie 7 od dnia rozwiązania tej
        Umowy, L’emir ma prawo naliczyć opłatę za postój Pojazdu Użytkownika na terenie APS i/lub
        korzystanie ze Skrytki w wysokości 100 zł za każdy dzień opóźnienia w odbiorze Pojazdu
        Użytkownika i/lub odbioru kluczyka ze Skrytki.
      </p>
      <p className={styles.box__paragraph}>
        3. Oświadczenie o rozwiązaniu Umowy Usługi L’emirGO może nastąpić na adres email lub poprzez
        wiadomość tekstową sms przesłaną Użytkownikowi/ Reprezentantowi Użytkownika wskazane w
        trakcie Rezerwacji.
      </p>
      <p className={styles.box__paragraph}>
        4. Rozwiązanie umowy o świadczenie usług elektronicznych lub Umowy Usługi L’emirGO nie
        wymaga wskazania przyczyny lub podania uzasadnienia.
      </p>
      <p className={styles.box__paragraph}>
        5. W każdym przypadku ustalenia odpowiedzialności Usługodawcy, jego pracowników,
        upoważnionych przedstawicieli i/lub pełnomocników, odpowiedzialność ta w stosunku do
        Użytkownika niebędącego Konsumentem, bez względu na jej podstawę prawną, jest ograniczona -
        zarówno w ramach pojedynczego roszczenia, jak również za wszelkie roszczenia w sumie - do
        wysokości 1000 zł.
      </p>
      <p className={styles.box__paragraph}>
        6. Wyłącza się jednocześnie odpowiedzialność Usługodawcy wobec Użytkownika nie będącego
        Konsumentem za utracone korzyści. Powyższe ograniczenia odpowiedzialności (ust. 5 i 6
        powyżej) nie mają zastosowania w przypadku, gdy szkoda powstała z winy umyślnej Usługodawcy
        lub osób wskazanych w ust. 5
      </p>
      <p className={styles.box__paragraph}>
        7. Wszelkie spory powstałe pomiędzy Usługodawcą a Użytkownikiem niebędącym Konsumentem
        zostają poddane sądowi właściwemu ze względu na siedzibę Usługodawcy.
      </p>
      <p className={styles.box__paragraph}>
        8. W odniesieniu do Użytkowników niebędących Konsumentami Sprzedawca może dokonać zmiany
        Regulaminu w każdym czasie na podstawie powszechnie obowiązujących przepisów prawa, jak
        również może odstąpić od Umowy Usługi L’emirGO do momentu przedstawienia Kalkulacji, o
        której mowa w §6 ust. 3 Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        9. W przypadku Użytkowników niebędących Konsumentami, Usługodawca ponosi odpowiedzialność za
        zdany mu Pojazd Użytkownika i kluczyki do tego Pojazdu wyłącznie w sytuacji gdy szkoda w tym
        Pojeździe powstała z wyłącznej winy Usługodawcy. Wyłącza się odpowiedzialność Usługodawcy za
        szkody powstałe na skutek kradzieży, zniszczenia Pojazdu Użytkownika przez osoby trzecie
        (nie będące pracownikami, współpracownikami lub innymi osobami, za które odpowiedzialność
        ponosi Usługodawca), jak również na skutek siły wyższej. Użytkownik nie będący Konsumentem
        przyjmuje do wiadomości, że Pojazd Użytkownika zostaje przez niego zdany na terenie APS,
        który nie jest strzeżony i ogrodzony, a tym samym przyjmuje na siebie ryzyko utraty tego
        Pojazdu Użytkownika na skutek kradzieży lub jego uszkodzenia przez osoby trzecie. Podobnie,
        Użytkownik, o którym mowa powyżej, przyjmuje do wiadomości, że kluczyk do Pojazdu
        Użytkownika jest przez niego deponowany w skrytce wyposażonej w zamek elektroniczny, lecz
        posadowionej na zewnątrz budynku APS, ryzyko to akceptując.
      </p>
      <p className={styles.box__paragraph}>
        10. Niniejszy paragraf Regulaminu, nie stosuje się do Użytkowników będących osobami
        fizycznymi prowadzącymi działalności gospodarczą, które zawierają z Usługodawcą Umowę Usługi
        L’emirGO, umowę o świadczenie usług na podstawie Regulaminu bezpośrednio związaną z ich
        działalnością gospodarczą, gdy z treści określonej umowy wynika, że nie posiada ona dla
        Użytkownika charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej
        przez niego działalności gospodarczej, określonego na podstawie przepisów o Centralnej
        Ewidencji i Informacji o Działalności Gospodarczej. Użytkownikom tym przysługuje również
        uprawnienie do odstąpienia od umowy na zasadach przewidzianych w §13 Regulaminu.
      </p>
      <p className={styles.box__paragraph}>
        11. W przypadku rozbieżności między pozostałymi paragrafami Regulaminu a niniejszym
        paragrafem, w zakresie Umów Usługi L’emir i usług świadczonych drogą elektroniczną zawartych
        między Usługodawcą a Użytkownikami nie będącymi Konsumentami, pierwszeństwo uzyskują
        postanowienia niniejszego paragrafu.
      </p>
      <h3 className={styles.box__title}>
        Postanowienia końcowe
        <p>§17</p>
      </h3>
      <p className={styles.box__paragraph}>1. Regulamin obowiązuje od dnia 1.02.2021.</p>
      <p className={styles.box__paragraph}>
        2. Umowy zawierane przez Usługodawcę za pośrednictwem Portalu L’emirGO (w tym umowa o
        świadczenie usług elektronicznych oraz Umowa Usługi L’emirGO) zawierane są w języku polskim
        i podlegają prawu polskiemu
      </p>
      <p className={styles.box__paragraph}>
        3. Wybór prawa polskiego na podstawie niniejszego Regulaminu nie pozbawia Konsumenta ochrony
        przyznanej mu na podstawie przepisów, których nie można wyłączyć w drodze umowy pomiędzy
        Usługodawcą a Konsumentem, na mocy prawa, które zgodnie z właściwymi regulacjami byłoby
        właściwe w przypadku braku wyboru.
      </p>
      <p className={styles.box__paragraph}>
        4. Korzystanie z poszczególnych, dalszych, usług świadczonych drogą elektroniczną, a nie
        objętych niniejszym Regulaminem (§4), jak i nie dotyczące Umowy Usługi L’emirGO, może
        odbywać się w oparciu o uregulowania szczególne, w szczególności odpowiednie regulaminy,
        cenniki, noty zawierające dane i informacje odnośnie przedmiotu, zakresu i parametrów tych
        usług.
      </p>
      <p className={styles.box__paragraph}>
        5. Użytkownik informowany jest i zapoznaje się z uregulowaniami szczególnymi wymienionymi
        ust. 4 odrębnie, ale w sposób odpowiadający właściwym przepisom prawa.
      </p>
      <p className={styles.box__paragraph}>6. Załączniki do niniejszego Regulaminu stanowią:</p>
      <p className={styles.box__paragraph}>
        - Klauzula Informacyjna (dot. ochrony danych osobowych)
      </p>
      <p className={styles.box__paragraph}>- Formularz odstąpienia od Umowy</p>
    </div>
  </>
);

export default TermsOfService;
