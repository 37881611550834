import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';
import Logo from '../../assets/logo/LemirGO Mono White.png';
import Instagram from '../../assets/logo/instagram_logo.svg';
import Facebook from '../../assets/logo/fb_logo.svg';
import Email from '../../assets/logo/email_logo.svg';

const Footer = ({ handleShowRegulations, handleShowPrivacy, handleShowRodo }) => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <section className={styles.footer__wrapper}>
        <header className={styles.logo}>
          <div>
            <img className={styles.logo__lemir} src={Logo} alt="Logo L'emir" title="L'EMIR" />
            <p className={styles.footer__title}>{t('footerTitle')}</p>
            <p className={styles.footer__paragraph}>{t('footerParagraph')}</p>
            <p className={styles.footer__paragraph}>{t('footerParagraphSecond')}</p>
          </div>
          <div className={styles.media}>
            <a
              className={styles.link__item}
              href="https://www.instagram.com/skodalemir/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.media__logo}
                src={Instagram}
                alt="Logo Instagram"
                title="Link do Instagram"
              />
            </a>
            <a
              className={styles.link__item}
              href="https://www.facebook.com/lemirskoda/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.media__logo}
                src={Facebook}
                alt="Logo Facebook"
                title="Link do Facebook"
              />
            </a>
            <a className={styles.link__item} href="mailto:salon@lemir.com.pl">
              <img className={styles.media__logo} src={Email} alt="Logo Email" title="E-mail" />
            </a>
          </div>
        </header>

        <div className={styles.link}>
          <button type="button" className={styles.link__item} onClick={handleShowRegulations}>
            {t('footerTermFirst')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowPrivacy}>
            {t('footerTermSecond')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowRodo}>
            {t('footerTermThird')}
          </button>
        </div>
        <p className={styles.footer__copyright}>{t('footerTermCopyright')}</p>
      </section>
    </footer>
  );
};

export default Footer;
