import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './map.module.scss';
import PhoneIcon from '../../assets/phone_icon.jpg';
import EmailIcon from '../../assets/email_icon.jpg';
import Instagram from '../../assets/instagram_icon.svg';
import Facebook from '../../assets/facebook_icon.svg';

const Map = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <header id="contact">
        <h2 className={styles.map__title}>{t('mapTitle')}</h2>
      </header>
      <div className={styles.map}>
        <div className={styles.map__description}>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraph}>{t('mapServiceTitle')}</p>
              <a className={styles.description__phone} href="tel:+32-508-80-00">
                32 508 80 00
              </a>
              <p className={styles.description__paragraphNormal}>{t('mapServiceHours')}</p>
            </div>
            <a className={styles.description__phone} href="tel:+32-508-80-00">
              <img className={styles.map__phoneIcon} src={PhoneIcon} alt="Ikona telefonu" />
            </a>
          </div>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraphMargin}>{t('mapSalonTitle')}</p>
              <a className={styles.description__phone} href="tel:+32-508-80-00">
                32 508 80 00
              </a>
              <p className={styles.description__paragraphNormal}>{t('mapSalonHours')}</p>
            </div>
            <a className={styles.description__phone} href="tel:+32-508-80-00">
              <img className={styles.map__phoneIcon} src={PhoneIcon} alt="Ikona telefonu" />
            </a>
          </div>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraphNormalMargin}>{t('mapEmail')}</p>
              <p className={styles.description__paragraphDecoration}>
                <a className={styles.description__phoneMail} href="mailto:salon@lemir.com.pl">
                  salon@lemir.com.pl
                </a>
              </p>
            </div>
            <a className={styles.description__phoneMail} href="mailto:salon@lemir.com.pl">
              <div className={styles.map__email}>
                <img className={styles.map__emailIcon} src={EmailIcon} alt="Ikona email" />
              </div>
            </a>
          </div>
          <div>
            <div>
              <p className={styles.description__paragraphMargin}>Social media:</p>
            </div>
            <div className={styles.description__mediaBox}>
              <a
                className={styles.description__link}
                href="https://www.instagram.com/skodalemir/"
                target="_blank"
                rel="noreferrer"
                title="Link do Instagram"
              >
                <img className={styles.description__socialIcon} src={Instagram} alt="Ikona email" />
              </a>
              <a
                className={styles.description__link}
                href="https://www.facebook.com/lemirskoda/"
                target="_blank"
                rel="noreferrer"
                title="Link do Facebook"
              >
                <img className={styles.description__socialIcon} src={Facebook} alt="Ikona email" />
              </a>
            </div>
            <div
              className={styles.description__qr}
              style={{
                background: `url('${process.env.PUBLIC_URL}/img/qr-code.svg')`,
                backgroundSize: 'cover',
              }}
            />
          </div>
        </div>
        <div className={styles.map__map} />
      </div>
    </div>
  );
};

export default Map;
