import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './description.module.scss';

const Description = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={styles.description} id="about">
        <div
          className={styles.description__photo}
          style={{
            background: `url('${process.env.PUBLIC_URL}/img/proces-obslugi-klienta-2.png')`,
            backgroundSize: 'cover',
            backgroundPositionX: -105,
          }}
        />
        <div className={styles.description__box}>
          <header>
            <h2 className={styles.description__title}>{t('descriptionTitle')}</h2>
          </header>
          <p className={styles.description__paragraph}>{t('descriptionParagraphFirst')}</p>
          <p className={styles.description__paragraph}>{t('descriptionParagraphSecond')}</p>
          <p className={styles.description__paragraph}>{t('descriptionParagraphThird')}</p>
        </div>
      </section>
      <section className={styles.descriptionOffer}>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/serwisowe-14.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -60,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFirst')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFirst')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/serwisowe-4.png')`,
              backgroundSize: 'cover',
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleSecond')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphSecond')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/serwisowe-3.png')`,
              backgroundSize: 'cover',
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleThird')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphThird')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/serwisowe-5.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -90,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFourth')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFourth')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/proces-obslugi-klienta-6.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -50,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFifth')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFifth')}
          </p>
        </div>
      </section>
    </>
  );
};

export default Description;
