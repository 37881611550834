import React from 'react';

import styles from './regulations.module.scss';
import Button from '../Button/button';
import TermsOfService from './components/termsOfService';

const Regulations = ({
  handleShowRegulations,
  handleShowPrivacy,
  handleShowRodo,
  handleCloseBoxRegulation,
}) => (
  <div className={styles.regulations}>
    <div className={styles.regulations__innerWrapper}>
      {handleShowRegulations && <TermsOfService />}
      {handleShowPrivacy && <TermsOfService />}
      {handleShowRodo && <TermsOfService />}
      <div className={styles.regulations__button}>
        <Button secondary onPress={handleCloseBoxRegulation}>
          Zamknij
        </Button>
      </div>
    </div>
  </div>
);

export default Regulations;
